import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import {
  hideModal,
  hideSecondaryModal,
  reviveModal,
  showModal,
  suspendModal,
} from 'actions/ModalActions';

// Utils function
import { asyncComponent } from '../../util/asyncComponent';
import featureFlags from '../../util/featureFlags';

// settings modals
const DeleteOtherRegistriesModal = asyncComponent(() =>
  import('pages/settings/otherRegistries/components/DeleteRegistryModal')
);
const EditOtherRegistriesModal = asyncComponent(() =>
  import('pages/settings/otherRegistries/components/EditRegistryModal')
);
const WebsitePublishWeddingDateModal = asyncComponent(() =>
  import('components/common/modals/WebsitePublishWeddingDateModal/WebsitePublishWeddingDateModal')
);
const WebsitePublishConfirmationModal = asyncComponent(() =>
  import('components/common/modals/WebsitePublishConfirmationModal/WebsitePublishConfirmationModal')
);
const RegistryForm = asyncComponent(() =>
  import('../manage/website/entities/RegistryContainer/Registry/RegistryForm')
);
const CropModal = asyncComponent(() => import('../manage/website/entities/CropModal'));

const ChecklistEditSettingsV2 = asyncComponent(() =>
  import('../manage/ChecklistV2/ChecklistEditSettings')
);
const GuestMessageModal = asyncComponent(() => import('../manage/guestlist/GuestMessageModal'));
const DeleteGuestGroupModal = asyncComponent(() =>
  import('../manage/guestlist/DeleteGuestGroupModal')
);
const DeleteChecklistModalV2 = asyncComponent(() =>
  import('../manage/ChecklistV2/ChecklistDeleteTaskModal')
);
const RemoveOrRevokePartnerModal = asyncComponent(() =>
  import('../manage/ChecklistV2/ChecklistRemoveOrRevokeModal')
);
const DeletePhotoModal = asyncComponent(() => import('../manage/website/DeletePhotoModal'));
const RegistryItemModal = asyncComponent(() => import('../registryitem/RegistryItem'));

const PublishRegistryModalv3 = asyncComponent(() =>
  import('../manage/website/PublishRegistryModalv3')
);
const PublishPageErrorModal = asyncComponent(() =>
  import('../manage/website/PublishPageErrorModal')
);
const PublishRsvpModal = asyncComponent(() => import('../manage/website/PublishRsvpModal'));
const SetShippingAddressModal = asyncComponent(() =>
  import('../manage/common/SetShippingAddressModal')
);
const RequestMissingInfoModalV2 = asyncComponent(() =>
  import('./modals/RequestMissingInfoModal/RequestMissingInfoModal')
);
const EventFormModalV2 = asyncComponent(() => import('../manage/common/EventFormModalV2'));
const AddGuestGroupModal = asyncComponent(() =>
  import('./modals/AddGuestGroupModal/AddGuestGroupModal')
);
const EditGuestGroupModal = asyncComponent(() =>
  import('./modals/EditGuestGroupModal/EditGuestGroupModal')
); // 👈 Invites (less stuff)
const EditGuestGroupModalWedding = asyncComponent(() =>
  import('./modals/EditGuestGroupWeddingsModal/EditGuestGroupWeddingsModal')
); // 👈 weddings (more tabs and fields)
const CheckGuestDuplicatesModal = asyncComponent(() =>
  import('../manage/guestlist/CheckGuestDuplicatesModal')
);
const BulkUninviteConfirmationModal = asyncComponent(() =>
  import('./modals/BulkUninviteConfirmationModal/BulkUninviteConfirmationModal')
);
const UninviteGuestConfirmationModal = asyncComponent(() =>
  import('./modals/UninviteGuestConfirmationModal/UninviteGuestConfirmationModal')
);
const ConfirmActionModal = asyncComponent(() =>
  import('./modals/ConfirmActionModal/ConfirmActionModal')
);
const DeclineRsvpRequestModal = asyncComponent(() =>
  import('./modals/DeclineRsvpRequestModal/DeclineRsvpRequestModal')
);

// seating chart:
const AddChartModal = asyncComponent(() => import('../manage/seatingchart/Modals/AddChart'));
const NewTableModal = asyncComponent(() => import('../manage/seatingchart/Modals/NewTable'));
const EditTableModal = asyncComponent(() => import('../manage/seatingchart/Modals/EditTable'));
const DeleteTableModal = asyncComponent(() => import('../manage/seatingchart/Modals/DeleteTable'));
const DeleteChartModal = asyncComponent(() => import('../manage/seatingchart/Modals/DeleteChart'));
const SeatingTipsModal = asyncComponent(() => import('../manage/seatingchart/Modals/SeatingTips'));

const ConfirmExitModal = asyncComponent(() => import('./modals/ConfirmExitModal/ConfirmExitModal'));

// invitations modals
const CancelOrderModal = asyncComponent(() =>
  import('../../cards/components/common/modals/CancelOrderModal/CancelOrderModal')
);

// guest manager modals
const LeavePromptModal = asyncComponent(() =>
  import('../../pages/Events/LeavePromptModal/LeavePromptModal')
);
const SuggestionModal = asyncComponent(() =>
  import('../../pages/Events/SuggestionModal/SuggestionModal')
);

const DeleteEventExceptionModal = asyncComponent(() =>
  import('./modals/DeleteEventExceptionModal/DeleteEventExceptionModal')
);

const DeleteRSVPEventModal = asyncComponent(() =>
  import('./modals/DeleteRSVPEventModal/DeleteRSVPEventModal')
);

const PhoneVerificationModal = asyncComponent(() =>
  import('../../pages/settings/PasswordSecurity/components/PhoneVerificationModal')
);

const PhoneVerificationConfirmEmailModal = asyncComponent(() =>
  import('../../pages/settings/PasswordSecurity/components/ConfirmEmailModal')
);

const AnimationModalsContainer = asyncComponent(() =>
  import('components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationModalsContainer')
);

const confirmExitModalFlag = featureFlags.get('confirmExitModalFlag');

const MODAL_COMPONENTS = {
  REGISTRY: RegistryForm,
  DELETE_GUEST_GROUP: DeleteGuestGroupModal,
  GUEST_MESSAGE: GuestMessageModal,
  CHECKLIST_EDIT_V2: ChecklistEditSettingsV2,
  DELETE_CHECKLIST_TASK_V2: DeleteChecklistModalV2,
  REMOVE_OR_REVOKE_PARTNER: RemoveOrRevokePartnerModal,
  DELETE_PHOTO: DeletePhotoModal,
  CROP_MODAL: CropModal,
  REGISTRY_ITEM: RegistryItemModal,
  WEBSITE_PUBLISH_WEDDING_DATE: WebsitePublishWeddingDateModal,
  WEBSITE_PUBLISH_CONFIRMATION: WebsitePublishConfirmationModal,

  PUBLISH_REGISTRY: PublishRegistryModalv3,
  PUBLISH_PAGE_ERROR: PublishPageErrorModal,
  PUBLISH_RSVP: PublishRsvpModal,
  SET_SHIPPING_ADDRESS: SetShippingAddressModal,
  REQUEST_MISSING_INFO_V2: RequestMissingInfoModalV2,

  EVENT_FORM: EventFormModalV2,

  ADD_GUEST_GROUP: AddGuestGroupModal,
  EDIT_GUEST_GROUP_WEDDING: EditGuestGroupModalWedding,
  CHECK_GUEST_DUPLICATES: CheckGuestDuplicatesModal,
  BULK_UNINVITE_CONFIRMATION: BulkUninviteConfirmationModal,
  UNINVITE_GUEST_CONFIRMATION: UninviteGuestConfirmationModal,
  CONFIRM_ACTION: ConfirmActionModal,

  ANIMATION_MODALS_CONTAINER: AnimationModalsContainer,
  /* other modals */
  DECLINE_RSVP_REQUEST: DeclineRsvpRequestModal,
  /* invitations modals */
  CANCEL_ORDER: CancelOrderModal,
  EDIT_GUEST_GROUP: EditGuestGroupModal,
  /* seating chart */
  ADD_CHART_MODAL: AddChartModal,
  NEW_TABLE_MODAL: NewTableModal,
  EDIT_TABLE_MODAL: EditTableModal,
  DELETE_TABLE_MODAL: DeleteTableModal,
  DELETE_CHART_MODAL: DeleteChartModal,
  SEATING_TIPS_MODAL: SeatingTipsModal,
  DELETE_OTHER_REGISTRIES_MODAL: DeleteOtherRegistriesModal,
  EDIT_OTHER_REGISTRIES_MODAL: EditOtherRegistriesModal,

  /* guest manager modals */
  GUEST_MANAGER_LEAVE_PROMPT_MODAL: LeavePromptModal,
  GUEST_MANAGER_SUGGESTION_MODAL: SuggestionModal,
  DELETE_EVENT_EXCEPTION: DeleteEventExceptionModal,
  /* tour modals */
  DELETE_RSVP_EVENT: DeleteRSVPEventModal,
  /* Account Settings Modals */
  PHONE_VERIFICATION_MODAL: PhoneVerificationModal,
  PHONE_VERIFICATION_CONFIRM_EMAIL_MODAL: PhoneVerificationConfirmEmailModal,
};

let currentModalForm;
class ModalRoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmExitModalHidden: true,
    };

    this.onBackgroundClick = this.onBackgroundClick.bind(this);
    this.onSecondaryBackgroundClick = this.onSecondaryBackgroundClick.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.hideConfirmExitModal = this.hideConfirmExitModal.bind(this);
    this.showConfirmExitModal = this.showConfirmExitModal.bind(this);
  }

  onBackgroundClick(e) {
    const { disableClose, hideModalFn, isCurrentModalFormDirty, modalProps } = this.props;
    if (confirmExitModalFlag) {
      if (!disableClose && e.target === e.currentTarget) {
        if (!isCurrentModalFormDirty) {
          if (modalProps && modalProps.onHideModal) {
            modalProps.onHideModal();
          }
          hideModalFn();
        } else {
          this.showConfirmExitModal();
        }
      }
    } else if (!disableClose && e.target === e.currentTarget) {
      if (modalProps && modalProps.onHideModal) {
        modalProps.onHideModal();
      }
      hideModalFn();
    }
  }

  onEscape({ keyCode }) {
    const { modalProps } = this.props;
    if (!modalProps.disableClose && keyCode === 27) {
      if (modalProps && modalProps.onHideModal) {
        modalProps.onHideModal();
      }
      this.props.hideModalFn();
    }
  }

  onHide() {
    if (typeof document !== 'undefined') {
      if (document.body) {
        document.body.className = document.body.className.replace(/ ?modal-open/g, '');
      }
      document.removeEventListener('keydown', this.onEscape);
    }
  }

  onSecondaryBackgroundClick(e) {
    const { hideSecondaryModalFn } = this.props;
    if (e.target === e.currentTarget) {
      hideSecondaryModalFn();
    }
  }

  onShow() {
    if (typeof document !== 'undefined') {
      if (document.body) {
        const orig = document.body.className;
        document.body.className = `${orig} modal-open`.trim();
      }
      document.addEventListener('keydown', this.onEscape);
    }
  }

  hideConfirmExitModal() {
    this.setState({
      isConfirmExitModalHidden: true,
    });
  }

  showConfirmExitModal() {
    this.setState({
      isConfirmExitModalHidden: false,
    });
  }

  render() {
    const {
      isSuspended,
      modalType,
      modalProps,
      hideModalFn,
      hideSecondaryModalFn,
      modalOptions,
      secondaryModal,
      showModalFn,
      isCurrentModalFormDirty,
      suspendModalFn,
      reviveModalFn,
    } = this.props;
    if (!modalType) {
      this.onHide();
      return null;
    }
    this.onShow();
    const SpecificModal = MODAL_COMPONENTS[modalType];
    const SpecificSecondaryModal = MODAL_COMPONENTS[secondaryModal.modalType];
    currentModalForm = modalProps ? modalProps.formIdentifier : undefined;
    return (
      <div className={modalOptions.className}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <div
          tabIndex="-1"
          role="dialog"
          className="modal in"
          style={{ zIndex: 1050, display: isSuspended ? 'none' : 'block' }}
          onClick={this.onBackgroundClick}
        >
          <div
            id={modalOptions && modalOptions.modalId ? modalOptions.modalId : null}
            className={`modal-dialog modal-${
              modalOptions && modalOptions.size ? modalOptions.size : 'lg'
            } ${modalOptions && modalOptions.className ? modalOptions.className : ''}`}
          >
            <div className="modal-content" style={{ ...modalOptions.styleOverride }}>
              {!modalProps.hideClose && (
                <button
                  type="button"
                  className="modal-close"
                  onClick={e => {
                    hideModalFn(e);
                    if (
                      modalProps &&
                      modalProps.onHideModal &&
                      typeof modalProps.onHideModal === 'function'
                    ) {
                      modalProps.onHideModal();
                    }
                    this.hideConfirmExitModal();
                  }}
                >
                  ×
                </button>
              )}
              <SpecificModal
                {...modalProps}
                hideModalFn={hideModalFn}
                showModalFn={showModalFn}
                suspendModalFn={suspendModalFn}
                reviveModalFn={reviveModalFn}
              />
            </div>
            {isCurrentModalFormDirty &&
              !this.state.isConfirmExitModalHidden &&
              confirmExitModalFlag && (
                <ConfirmExitModal
                  hideModalFn={hideModalFn}
                  hideConfirmExitModal={this.hideConfirmExitModal}
                />
              )}
          </div>
        </div>
        <div className="modal-backdrop in" style={{ display: isSuspended ? 'none' : 'block' }} />
        {SpecificSecondaryModal && (
          <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <div
              tabIndex="-1"
              role="dialog"
              className="modal in"
              style={{ zIndex: 1060, display: 'block' }}
              onClick={this.onSecondaryBackgroundClick}
            >
              <div
                className={`modal-dialog modal-${
                  secondaryModal.modalOptions && secondaryModal.modalOptions.size
                    ? secondaryModal.modalOptions.size
                    : 'sm'
                } ${modalOptions && modalOptions.className ? modalOptions.className : ''}`}
                style={{ margin: '60px auto' }}
              >
                <div className="modal-content">
                  {!secondaryModal.modalProps.hideClose && (
                    <button type="button" className="modal-close" onClick={hideSecondaryModalFn}>
                      ×
                    </button>
                  )}
                  <SpecificSecondaryModal
                    {...secondaryModal.modalProps}
                    hideModalFn={hideSecondaryModalFn}
                    showModalFn={showModalFn}
                  />
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" style={{ zIndex: '1055' }} />
          </div>
        )}
      </div>
    );
  }
}

ModalRoot.propTypes = {
  isSuspended: PropTypes.bool,
  modalType: PropTypes.string,
  modalProps: PropTypes.shape({
    callback: PropTypes.func,
  }),
  hideModalFn: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    size: PropTypes.string,
    formIdentifier: PropTypes.string,
    modalId: PropTypes.string,
  }),
  secondaryModal: PropTypes.shape({}),
  hideSecondaryModalFn: PropTypes.func,
  showModalFn: PropTypes.func.isRequired,
  styleOverride: PropTypes.shape({}),
  suspendModalFn: PropTypes.func.isRequired,
  reviveModalFn: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.modal,
  isCurrentModalFormDirty: currentModalForm ? isDirty(currentModalForm)(state) : null,
});

const mapDispatchToProps = {
  hideModalFn: hideModal,
  hideSecondaryModalFn: hideSecondaryModal,
  showModalFn: showModal,
  suspendModalFn: suspendModal,
  reviveModalFn: reviveModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
