import React, { useLayoutEffect, useState } from 'react';
import cx from 'classnames';
import type { WCmsVideoView } from '@zola/svc-web-api-ts-client';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { OuterContainer, VideoContainer, Video, Caption } from './VideoSnippet.styles';

type VideoSnippetProps = Pick<WCmsVideoView, 'id' | 'url' | 'caption'> & {
  additionalPaddingBottom?: boolean;
  className?: string;
  textAlignment?: string;
};

const VideoSnippet = ({
  id,
  url,
  caption,
  additionalPaddingBottom,
  className,
  textAlignment = 'CENTER',
}: VideoSnippetProps): JSX.Element => {
  const [renderAfterFirstRender, setRenderAfterFirstRender] = useState(false);
  const {
    state: {
      components: { styleCmsEntityBodyFont },
    },
  } = useWebsiteThemeContext();

  useLayoutEffect(() => {
    if (!renderAfterFirstRender) {
      setRenderAfterFirstRender(true);
    }
  }, []);

  const StyledCaption = styleCmsEntityBodyFont(Caption);

  return (
    <OuterContainer
      className={cx('video-outer-container', className, {
        'video-outer-container__paddingBottom': additionalPaddingBottom,
      })}
    >
      <VideoContainer className="video-container">
        {renderAfterFirstRender && (
          <Video
            className="video-iframe"
            width="800"
            height="450"
            src={url}
            title={`Video player ${id}`}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </VideoContainer>
      {caption && (
        <StyledCaption className="video-caption" textAlignment={textAlignment.toLowerCase()}>
          {caption}
        </StyledCaption>
      )}
    </OuterContainer>
  );
};

export default VideoSnippet;
