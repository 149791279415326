import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { ButtonContainer } from 'components/publicWebsiteV2/pages/Schedule/Schedule.styles';

export const HorizontalColumn = styled.div<{
  orientation: string;
}>`
  h5 {
    display: none;
  }
  ${({ theme }) => theme.MEDIA_QUERY.DESKTOP} {
    ${({ orientation, theme }) =>
      orientation === 'HORIZONTAL' &&
      css`
        padding: 0 ${theme.SPACING.S24};
        margin: auto;
        flex: 50%;
        flex-direction: column;
        p {
          line-height: 32px;
          margin-top: ${theme.SPACING.S24};
        }
        h5 {
          display: block;
        }

        ${ButtonContainer} {
          margin-top: ${SPACING.S24};
        }
      `};
  }
`;

export const Divider = styled.div`
  position: absolute;
  height: 100%;
  left: 50%;
  width: 1px;

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;
