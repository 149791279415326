import React, { FC, useRef, Fragment } from 'react';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { AnimationProps } from '@zola/zola-ui/src/animations/PageAnimations/types';
import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import {
  SlideshowContainer,
  SlideshowSlider,
  SlideSized,
  TimerBarContainer,
  TimerContainer,
  Timer,
} from './WebsiteSlideshow.styles';
import { PageAnimationWrapper } from '../PageAnimationWrapper/PageAnimationWrapper';
import { getAnimationPropsSlideshowHero } from '../OverlayAnimation/pageAnimationUtils';

export type WebsiteSlideshowProps = {
  slideshowImages: Array<string>;
  inPreview?: 'DESKTOP' | 'MOBILE';
  isSinglePage?: boolean;
};

const WebsiteSlideshow: FC<WebsiteSlideshowProps> = ({
  slideshowImages,
  inPreview,
  isSinglePage,
}) => {
  const delay = 6000;
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef<ReturnType<typeof setInterval> | null>(null);

  const {
    state: { wedding },
  } = useWebsiteThemeContext();

  const { pageAnimation } = useAnimationContext();

  React.useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => setIndex(prevIndex => (prevIndex === slideshowImages.length - 1 ? 0 : prevIndex + 1)),
      delay
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [slideshowImages.length, index]);

  // TODO: move outside WebsiteSlideshow?
  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  const isSinglePageLayout =
    ((wedding?.public_theme_v2?.layout_type as unknown) as string) === 'SINGLE_PAGE';

  const MaybePageAnimationWrapper = isSinglePageLayout ? Fragment : PageAnimationWrapper;
  const animationWrapperProps = isSinglePageLayout
    ? {}
    : {
        animationProps: {
          isHero: true,
          direction: { wipeDirection: 'down', panDirection: 'right' },
          ...getAnimationPropsSlideshowHero(pageAnimation),
        } as AnimationProps,
        style: { height: '100%', display: 'block' },
        fullHeight: true,
      };

  return (
    <SlideshowContainer ref={containerRef}>
      <MaybePageAnimationWrapper {...animationWrapperProps}>
        <SlideshowSlider isSinglePage={isSinglePage}>
          {slideshowImages.map((link, idx) => (
            <SlideSized
              imagesLength={slideshowImages.length}
              isSinglePage={isSinglePage}
              key={`hero-slideshow-image-${idx}`}
              inPreview={inPreview}
              src={link}
              alt={`Hero Slideshow Image ${idx}`}
              containerWidth={containerWidth}
            />
          ))}
        </SlideshowSlider>
        <TimerBarContainer inPreview={inPreview}>
          {slideshowImages.map((_, idx) => (
            <TimerContainer key={idx} inPreview={inPreview}>
              <Timer key={`slideshow-timer-${idx}`} active={idx === index} inPreview={inPreview} />
            </TimerContainer>
          ))}
        </TimerBarContainer>
      </MaybePageAnimationWrapper>
    </SlideshowContainer>
  );
};

export default WebsiteSlideshow;
